import React from 'react';
import { navigate } from 'gatsby';

import 'components/style.scss';
import './work-item.scss';

const WorkItem = ({ count, title, description, url, descriptionLeftMargin }) => {
  const onClick = () => {
    navigate(url);
  };

  return (
    <button
      className="inverted-button height-fix padding-fix padding-fix-mobile"
      style={{ width: '100%', marginTop: '1rem' }}
      onClick={onClick}
    >
      <div className="workItemContainer">
        <div className="workItemIndex">
          <p className="title is-work-item-title is-family-secondary is-pulled-left" style={{ fontWeight: '400' }}>
            /{count}
          </p>
        </div>
        <div className="workItemTitle">
          <p className="title is-work-item-title is-family-secondary is-pulled-left" style={{ fontWeight: '400' }}>
            {title}
          </p>
        </div>
        <div className="workItemDescription">
          <p
            className="title is-description-title is-pulled-left"
            style={{
              fontWeight: '300',
              textAlign: 'left',
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              paddingTop: '6px',
            }}
          >
            {description}
          </p>
        </div>
      </div>
    </button>
  );
};

export default WorkItem;
