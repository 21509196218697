import React, { useState } from 'react';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import Swiper from 'react-id-swiper';

import 'components/style.scss';
import './company-carousel.scss';

import logo_beyond from 'images/client_logo_beyond.png';
import logo_cuttingedge from 'images/client_logo_cuttingedge.png';
import logo_dentsu from 'images/client_logo_dentsu.png';
import logo_ea from 'images/client_logo_ea.png';
import logo_intel from 'images/client_logo_intel.png';
import logo_lenovo from 'images/client_logo_lenovo.png';
import logo_rockstar from 'images/client_logo_rockstar.png';
import logo_sony from 'images/client_logo_sony.png';

const CompanyCarousel = () => {
  const params = {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 1.25,
    breakpoints: {
      550: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      },
      900: {
        slidesPerView: 4,
      },
      1024: {
        slidesPerView: 5,
      },
      1200: {
        slidesPerView: 6,
      },
      1450: {
        slidesPerView: 7,
      },
      1600: {
        slidesPerView: 8,
      },
    },
    //slideClass: 'swiperSlide',
  };

  const [swiper, setSwiper] = useState(null);

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  const imageStyle = {
    height: '100px',
    width: '160px',
  };

  return (
    <div className="swiperContainer">
      <button className="swiperButton" onClick={goPrev}>
        <IoIosArrowBack size="3em" color="black" />
      </button>
      <Swiper {...params} getSwiper={setSwiper}>
        <div style={imageStyle}>
          <img className="centerImage" src={logo_ea} />
        </div>
        <div style={imageStyle}>
          <img className="centerImage" src={logo_intel} />
        </div>
        <div style={imageStyle}>
          <img className="centerImage" src={logo_lenovo} />
        </div>
        <div style={imageStyle}>
          <img className="centerImage" src={logo_rockstar} />
        </div>
        <div style={imageStyle}>
          <img className="centerImage" src={logo_sony} />
        </div>
        <div style={imageStyle}>
          <img className="centerImage" src={logo_beyond} />
        </div>
        <div style={imageStyle}>
          <img className="centerImage" src={logo_cuttingedge} />
        </div>
        <div style={imageStyle}>
          <img className="centerImage" src={logo_dentsu} />
        </div>
      </Swiper>
      <button className="swiperButton" onClick={goNext}>
        <IoIosArrowForward size="3em" color="black" />
      </button>
    </div>
  );
};

export default CompanyCarousel;
