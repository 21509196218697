import React from 'react';
import Swiper from 'react-id-swiper';
import Img from 'gatsby-image';
import { Link, useStaticQuery, graphql } from 'gatsby';

import 'components/style.scss';
import './main-carousel.scss';

const MainCarousel = () => {
  const data = useStaticQuery(graphql`
    query {
      banner1: file(relativePath: { eq: "banner_1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      banner2: file(relativePath: { eq: "banner_2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      banner3: file(relativePath: { eq: "banner_3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      banner4: file(relativePath: { eq: "banner_4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      banner5: file(relativePath: { eq: "banner_5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      banner6: file(relativePath: { eq: "banner_6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      banner7: file(relativePath: { eq: "banner_7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      banner8: file(relativePath: { eq: "banner_8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      banner9: file(relativePath: { eq: "banner_9.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const params = {
    autoplay: {
      delay: 5000,
    },
    containerClass: 'swiper-container bordered-image',
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  };

  return (
    <div>
      <Swiper {...params}>
        <Img className="bordered-image" fluid={data.banner1.childImageSharp.fluid} />
        <Img className="bordered-image" fluid={data.banner2.childImageSharp.fluid} />
        <Img className="bordered-image" fluid={data.banner3.childImageSharp.fluid} />
        <Img className="bordered-image" fluid={data.banner4.childImageSharp.fluid} />
        <Img className="bordered-image" fluid={data.banner5.childImageSharp.fluid} />
        <Img className="bordered-image" fluid={data.banner6.childImageSharp.fluid} />
        <Img className="bordered-image" fluid={data.banner7.childImageSharp.fluid} />
        <Img className="bordered-image" fluid={data.banner8.childImageSharp.fluid} />
        <Img className="bordered-image" fluid={data.banner9.childImageSharp.fluid} />
      </Swiper>
    </div>
  );
};

export default MainCarousel;
