import React from 'react';

import 'components/style.scss';
import FeaturedWork from 'components/featured-work';
import Detail from 'components/detail';
import Footer from 'components/footer';

const Layout = ({ children }) => (
  <>
    <FeaturedWork />
    <Detail />
    <Footer />
  </>
);

export default Layout;
