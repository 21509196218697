import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { StaticQuery, graphql } from 'gatsby';

import 'components/style.scss';
import './footer.scss';

import big62_stacked from 'images/big62_stacked.svg';

const Footer = () => (
  <StaticQuery
    query={graphql`
      query SocialQuery {
        site {
          siteMetadata {
            facebook
            instagram
            linkedin
          }
        }
      }
    `}
    render={data => (
      <div className="big62-section">
        <div className="big62-container">
          <div className="footerContainer large-margin-top">
            <div>
              <p className="title is-medium-title is-family-secondary is-marginless">GET IN TOUCH</p>
              <a className="title is-large-email is-family-secondary has-text-big62pink" href="mailto:hello@big62.com">
                HELLO@BIG62.COM
              </a>
            </div>
            <div>
              <div style={{ marginLeft: '-10px', paddingTop: '0.0rem' }}>
                <span className="icon is-large">
                  <a href={data.site.siteMetadata.facebook} title="Facebook">
                    <FaFacebook size="2rem" color="black" />
                  </a>
                </span>
                <span className="icon is-large">
                  <a href={data.site.siteMetadata.instagram} title="Instagram">
                    <FaInstagram size="2rem" color="black" />
                  </a>
                </span>
                <span className="icon is-large">
                  <a href={data.site.siteMetadata.linkedin} title="LinkedIn">
                    <FaLinkedin size="2rem" color="black" />
                  </a>
                </span>
              </div>
            </div>
            <img className="bottomLogoImage" src={big62_stacked} style={{ width: '128px', height: '89px' }} />
            <div>
              <span className="big62Text">©BIG62 Pty Ltd</span>
              <span className="dateText">1.0.20.7786</span>
            </div>
          </div>
        </div>
      </div>
    )}
  />
);

export default Footer;
