import React from 'react';
import { FaHeart } from 'react-icons/fa';
import MainCarousel from 'components/parts/main-carousel';
import WorkItem from 'components/parts/work-item';

import 'components/style.scss';

const FeaturedWork = () => (
  <section className="big62-section" style={{ paddingTop: '1rem' }}>
    <div className="big62-container">
      <div className="margin-bottom-2">
        <h1 className="title is-main-title is-family-secondary">
          WE
          <FaHeart color="#000000" style={{ transform: 'translateY(10px)', width: '1.7em', height: '1.4em' }} />
          INNOVATING BIG IDEAS WITH DESIGN + TECHNOLOGY
        </h1>
      </div>
      <div style={{ paddingTop: '1rem' }}>
        <MainCarousel />
      </div>
      <div style={{ paddingTop: '5rem', marginBottom: '-4em' }}>
        <h2 className="title is-main-title is-family-secondary">FEATURED WORK</h2>
      </div>
      <div className="large-margin-top" style={{ marginBottom: '-2.75em' }}>
        <WorkItem
          count="01"
          title="IVEBI"
          description="Financial web application"
          url="/work/ivebi"
          descriptionLeftMargin="-4px"
        />
        <WorkItem count="02" title="PROJECT LEX" description="3d action mobile game" url="/work/projectlex" />
        <WorkItem count="03" title="HUMANTRAK" description="Movement analysis application" url="/work/humantrak" />
        <WorkItem count="04" title="SANTOS TOUR" description="UCI World Tour TV broadcast" url="/work/santostour" />
      </div>
    </div>
  </section>
);

export default FeaturedWork;
