import React from 'react';
import CompanyCarousel from 'components/parts/company-carousel';

import 'components/style.scss';

import imageX from 'images/icon_x.svg';

const FeaturedWork = () => (
  <section className="big62-section">
    <div className="big62-container large-margin-top">
      <div>
        <h2 className="title is-main-title is-family-secondary">WE LOVE WHAT WE DO!</h2>
      </div>
      <div className="content" style={{ paddingTop: '1rem' }}>
        <p className="is-body-small-text fix-spacing">
          BIG62 is a digital creative agency. We meld creative with engineering, working in every aspect of design &
          technology, including software development, mobile, games, broadcast and digital advertising.
        </p>
      </div>
      <div className="columns is-centered is-desktop small-margin-top">
        <div className="column is-narrow special-padding" style={{ zIndex: '1', position: 'relative' }}>
          <div className="content">
            <h3 className="title is-detail-header is-family-secondary">DESIGN</h3>
            <ul className="title is-detail-text is-family-secondary is-marginless responsive-tweak">
              <li>Application Design</li>
              <li>User Interface &amp; Experience</li>
              <li>2D/3D Animation</li>
              <li>Brand Development</li>
              <li>Broadcast Graphics</li>
              <li>Digital Advertising</li>
            </ul>
          </div>
        </div>
        <div className="column is-1-tablet is-2-desktop is-4-widescreen is-4-fullhd">
          <img
            className="is-hidden-touch"
            src={imageX}
            style={{
              minWidth: '440px',
              maxWidth: '440px',
              position: 'relative',
              left: '44%',
              transform: 'translateX(-50%)',
            }}
          />
          <img
            className="is-hidden-desktop"
            src={imageX}
            style={{ minWidth: '270px', width: '100%', maxWidth: '440px' }}
          />
        </div>
        <div className="column is-narrow special-padding" style={{ zIndex: '1', position: 'relative' }}>
          <div className="content">
            <h3 className="title is-detail-header is-family-secondary">TECHNOLOGY</h3>
            <ul className="title is-detail-text is-family-secondary is-marginless responsive-tweak">
              <li>Prototyping</li>
              <li>Web Application Development</li>
              <li>Mobile Application Development</li>
              <li>Game Development</li>
              <li>ECommerce</li>
              <li>Content Management</li>
              <li>Full-Stack Engineering</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="large-margin-top">
        <h3 className="title is-medium-title is-family-secondary">Companies our experienced team has worked with...</h3>
        <br />
      </div>
      <div>
        <CompanyCarousel />
      </div>
    </div>
  </section>
);

export default FeaturedWork;
